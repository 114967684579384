@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #00ff00;
  --background: #000000;
  --foreground: #ffffff;
}

body {
  @apply flex flex-col items-center justify-center min-h-screen;
  background: var(--background);
  color: var(--foreground);
  font-family: monospace;
}

.tic-tac-toe {
  @apply flex flex-col items-center;
  padding-top: 2rem;
}

.status {
  @apply text-3xl font-bold;
  color: var(--primary);
  text-align: center;
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
  min-width: 240px;
  display: inline-block;
  z-index: 2;
  /* Keep status above board */
  backface-visibility: hidden;
  /* Help prevent any transform issues */
}

.status span {
  display: block;
  /* animation: fadeIn 0.3s ease-out; */
}

.status::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  height: 1px;
  background: var(--primary);
  opacity: 0.3;
  width: 0;
}

.square svg {
  opacity: 0;
  transform: scale(1.4);
}

/* Update the desktop mark appearance animation */
.square:not(:empty) svg {
  animation: markAppear 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
    markColorTransition 0.3s ease-out forwards;
}

.status span::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  height: 1px;
  background: var(--primary);
  opacity: 0.3;
  width: 100%;
  animation: expandWidth 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes mobileBoardAppear {
  0% {
    opacity: 0;
    transform: scale(0.95) translateY(15px);
    filter: brightness(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
    filter: brightness(1);
  }
}

@media (max-width: 768px) {
  .status {
    font-size: 1.25rem;
    padding: 0.25rem 0.5rem;
    min-width: 200px;
    margin-bottom: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--background);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 10;
    transform: translateZ(0);
    /* Prevent potential layout shifts */
  }

  .status span::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 1px;
    background: var(--primary);
    opacity: 0.3;
    width: 100%;
    z-index: 11;
    animation: expandWidth 0.3s linear forwards;
    will-change: width; /* Add this line */
  }

  .tic-tac-toe {
    padding-top: 6rem;
    /* Increase top padding to avoid the fixed header */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .game-center {
    margin-top: 0;
    /* Remove top margin since status is fixed */
  }

  .desktop {
    display: none !important;
    /* Force hide desktop elements on mobile */
  }

  .mobile-scoreboard {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 255, 0, 0.05);
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    /* margin: 0.5rem 0; */
    gap: 1.5rem;
    margin-bottom: 6rem;
  }

  .tic-tac-toe:has(.play-again) .mobile-scoreboard {
    margin-bottom: 9rem;
  }

  .score-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .score-label {
    font-size: 1rem;
    color: var(--foreground);
  }

  .score-value {
    font-size: 1.5rem;
    color: var(--primary);
  }

  .score-separator {
    color: var(--foreground);
    opacity: 0.3;
  }

  .board {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: min(300px, 90vw);
    aspect-ratio: 1;
    margin: 1rem auto;
    position: relative;
    gap: 0;
    border-collapse: collapse;
    background: var(--background);
    padding: 0;
    overflow: hidden;
    animation: mobileBoardAppear 0.5s cubic-bezier(0.33, 1, 0.68, 1) forwards;
    /* Prevent any pixel gaps */
  }

  .square {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    cursor: pointer;
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: var(--background);
    border: none;
    height: 80px;
    width: 80px;
    transition: transform 0.2s ease, color 0.2s ease;
    /* Disable tap highlight & allow immediate active state */
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
  }

  .square svg {
    width: 40px;
    height: 40px;
    opacity: 0;
    transform: scale(0.8);
    transition: transform 0.2s ease, color 0.3s ease;
  }

  /* Update animation for mobile marks */
  .square:not(:empty) svg {
    color: var(--primary);
    animation: mobileMarkAppear 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards, mobileMarkColorTransition 0.3s ease-out forwards;
  }

  /* Add transition delay for color change */
  .square:not(:empty):not(.winning-line) svg {
    transition: transform 0.2s ease, color 0.3s ease 0.1s;
    color: var(--foreground);
    /* Transition to white */
  }

  /* Remove these overrides that were canceling the animations */
  .square:hover:not(:disabled) svg {
    transform: scale(1.2);
    color: var(--primary);
  }

  .winning-line svg {
    animation: mobileWinningMark 1s ease-in-out infinite alternate !important;
  }

  /* .square:active:not(:disabled) {
    transform: scale(0.95);
  } */

  .square:hover:not(:disabled) {
    color: var(--primary);
  }

  .square:hover:not(:disabled) svg {
    transform: scale(1.7);
    color: var(--primary);
  }

  .winning-line svg {
    animation: winningMark 1s ease-in-out infinite alternate !important;
  }

  /* .board {
    opacity: 0;
    transform: scale(0.98) translateY(5px);
    animation: mobileBoardAppear 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  } */

  /* Middle column */
  .square:nth-child(2),
  .square:nth-child(5),
  .square:nth-child(8) {
    border-left: 1px solid var(--foreground);
  }

  /* Middle row */
  .square:nth-child(4),
  .square:nth-child(5),
  .square:nth-child(6) {
    border-top: 1px solid var(--foreground);
  }

  /* Overlap fix for middle square */
  .square:nth-child(5) {
    z-index: 1;
  }

  .board::before,
  .board::after {
    display: none;
  }

  .square:nth-child(3n) {
    border-right: none;
  }

  .square:nth-child(n+7) {
    border-bottom: none;
  }

  .game-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 5rem;
    /* Space for bottom controls */
  }

  .controls {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    background: var(--background);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  /* Middle column squares */
  .square:nth-child(2),
  .square:nth-child(5),
  .square:nth-child(8) {
    border-left: 1px solid var(--foreground);
    border-right: 1px solid var(--foreground);
  }

  /* Middle row squares */
  .square:nth-child(4),
  .square:nth-child(5),
  .square:nth-child(6) {
    border-top: 1px solid var(--foreground);
    border-bottom: 1px solid var(--foreground);
  }

  /* Fix overlapping borders */
  .square:nth-child(2),
  .square:nth-child(8) {
    width: calc(100% + 4px);
    margin-left: -2px;
  }

  .square:nth-child(4),
  .square:nth-child(6) {
    height: calc(100% + 4px);
    margin-top: -2px;
  }

  /* Middle square special case */
  .square:nth-child(5) {
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    margin: -2px;
    z-index: 1;
  }

  /* Remove board lines since we're using borders */
  .board::before,
  .board::after {
    display: none;
  }

  .winning-line {
    color: var(--primary) !important;
  }

  .winning-line svg {
    animation: winningMark 1s ease-in-out infinite alternate !important;
    color: inherit !important;
  }

  .square:hover:not(:disabled) svg {
    transform: none;
    color: var(--primary);
  }

  .square:active:not(:disabled) {
    color: var(--primary);
  }

  .square:active:not(:disabled) svg {
    transform: scale(1.7);
    color: var(--primary);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes expandWidth {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.board {
  display: grid;
  grid-template-columns: repeat(3, 160px);
  position: relative;
  gap: 0;
  margin: 1rem 0;
  opacity: 0;
  filter: brightness(0.5);
  transform: scale(0.95) translateY(10px);
  transform-origin: center center;
  /* Add this to stabilize the scaling */
  will-change: transform;
  /* Add this to optimize the animation */
  z-index: 1;
  /* Ensure board animations don't affect other elements */
  backface-visibility: hidden;
  /* Add this to help with performance */
}

@media (max-width: 768px) {
  .board {
    grid-template-columns: repeat(3, 1fr);
    /* Adjust to fit the screen */
    width: 100%;
    /* Make the board take full width */
    max-width: 320px;
    /* Set a max-width for better appearance */
    margin: 0 auto;
    /* Center the board */
  }

  .board::before {
    top: calc(33.33% - 2px);
    /* Adjust top position */
    height: 2px;
    /* Adjust height */
    box-shadow: 0 80px 0 var(--foreground);
    /* Adjust shadow */
  }

  .board::after {
    left: calc(33.33% - 2px);
    /* Adjust left position */
    width: 2px;
    /* Adjust width */
    box-shadow: 80px 0 0 var(--foreground);
    /* Adjust shadow */
  }
}

@keyframes boardAppear {
  0% {
    opacity: 0;
    transform: scale(0.95) translateY(10px) translateZ(0);
    filter: brightness(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0) translateZ(0);
    filter: brightness(1);
  }
}

.board::before,
.board::after {
  content: '';
  position: absolute;
  background-color: var(--foreground);
}

.board::before {
  left: 0;
  width: 100%;
  height: 4px;
  box-shadow: 0 160px 0 var(--foreground);
  top: calc(33.33% - 2px);
  transform-origin: center;
  transform: scaleX(0);
  animation: horizontalLines 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) 0.3s forwards;
}

.board::after {
  top: 0;
  height: 100%;
  width: 4px;
  box-shadow: 160px 0 0 var(--foreground);
  left: calc(33.33% - 2px);
  transform-origin: top;
  transform: scaleY(0);
  animation: verticalLines 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s forwards;
}

@keyframes horizontalLines {
  from {
    transform: scaleX(0);
    opacity: 0;
  }

  to {
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes verticalLines {
  from {
    transform: scaleY(0);
    opacity: 0;
  }

  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

.square {
  height: 160px;
  width: 160px;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--foreground);
  cursor: pointer;
  transition: color 0.2s ease;
}

@media (max-width: 768px) {
  .square {
    height: 80px;
    /* Adjust height for mobile */
    width: 80px;
    /* Adjust width for mobile */
  }

  .square svg {
    width: 40px;
    /* Adjust SVG size for mobile */
    height: 40px;
    /* Adjust SVG size for mobile */
    transform: scale(1.2);
    /* Adjust scale for better appearance */
  }
}

@keyframes markAppear {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1.4);
  }
}

/* Add new desktop color transition animation */
@keyframes markColorTransition {
  0% {
    color: var(--primary);
  }

  100% {
    color: var(--foreground);
  }
}

/* Update hover state to always show primary color */
.square:hover:not(:disabled) svg {
  transform: scale(1.7);
  color: var(--primary) !important;
}

.square:hover:not(:disabled) {
  color: var(--primary);
}

.square:disabled {
  @apply cursor-not-allowed opacity-90;
}

.winning-line {
  color: var(--primary);
}

/* Ensure winning line marks stay primary colored */
.winning-line svg {
  opacity: 1 !important;
  animation: winningMark 1s ease-in-out infinite alternate !important;
  color: var (--primary) !important;
}

@keyframes winningMark {
  0% {
    transform: scale(1.4);
    filter: brightness(1);
  }

  50% {
    transform: scale(1.6);
    filter: brightness(1.5);
  }

  100% {
    transform: scale(1.4);
    filter: brightness(1);
  }
}

button[class*="play-again"] {
  @apply px-6 py-2;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: var(--primary);
  color: var(--background);
  border: none;
  cursor: pointer;
  opacity: 0;
  transform: scale(0.9);
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  animation: buttonAppear 0.3s ease-out forwards;
}

@media (max-width: 768px) {
  button[class*="play-again"] {
    font-size: 0.8rem;
    height: 34px;
  }
}

@keyframes buttonAppear {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

button[class*="play-again"]:hover {
  opacity: 0.9;
}

.game-container {
  display: flex;
  align-items: center;
  gap: 4rem;
  margin: 2rem 0;
}

@media (max-width: 768px) {
  .game-container {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 0 1rem;
  }

  .score-x,
  .score-o {
    display: none;
    /* Hide desktop scoreboard */
  }

  /* .mobile-scoreboard {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 255, 0, 0.05);
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    margin: 0.5rem 0;
    gap: 1.5rem;
  } */

  .score-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .score-label {
    font-size: 1rem;
    color: var(--foreground);
  }

  .score-value {
    font-size: 1.5rem;
    color: var(--primary);
  }

  .score-separator {
    color: var(--foreground);
    opacity: 0.3;
  }
}

@media (min-width: 769px) {
  .board {
    animation: boardAppear 0.6s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  }

  .mobile-scoreboard {
    display: none;
    /* Hide on desktop */
  }

  .score-x,
  .score-o {
    display: flex;
    /* Ensure desktop scoreboard is visible */
  }

  .desktop {
    display: flex;
    /* Ensure elements with .desktop class are visible on desktop */
  }

  .game-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .score-x,
  .score-o {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    min-width: 120px;
  }

  .score-label {
    font-size: 1.25rem;
    color: var(--foreground);
    opacity: 0.8;
  }

  .score-value {
    font-size: 2rem;
    color: var(--primary);
    font-weight: bold;
  }
}

.score-x,
.score-o {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  min-width: 120px;
}

@media (max-width: 768px) {

  .score-x,
  .score-o {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    background: rgba(0, 255, 0, 0.05);
    border-radius: 8px;
    margin: 0;
  }
}

.score-label {
  font-size: 1.25rem;
  color: var(--foreground);
  opacity: 0.8;
}

@media (max-width: 768px) {
  .score-label {
    font-size: 1rem;
    min-width: 80px;
  }
}

.score-value {
  font-size: 3rem;
  font-weight: bold;
  color: var(--primary);
  position: relative;
  transition: transform 0.2s ease;
}

@media (max-width: 768px) {
  .score-value {
    font-size: 1.5rem;
    transform-origin: center;
  }

  .score-value[data-updated="true"] {
    animation: mobileScoreUpdate 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.score-value::after {
  content: attr(data-value);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation: scoreUpdate 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes scoreUpdate {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes mobileScoreUpdate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
    color: var(--primary);
  }

  100% {
    transform: scale(1);
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  /* Reduced from 1rem or 2rem */
  margin-top: 2rem;
  width: 100%;
  max-width: 600px;
}

@media (max-width: 768px) {
  .controls {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    padding: 0 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    background: var(--background);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0;
    gap: 0.5rem;
  }
}

/* When play-again button appears, add a bit more spacing around it */
.controls:has(.play-again) {
  gap: 1rem;
}

.mode-toggle {
  display: flex;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid var(--primary);
  height: 38px;
  width: 142px;
}

@media (max-width: 768px) {
  .mode-toggle {
    font-size: 0.8rem;
    height: 34px;
  }
}

.toggle-btn {
  @apply px-4;
  flex: 1;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: transparent;
  color: var(--foreground);
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-btn.active {
  background: var(--primary);
  color: var(--background);
}

.toggle-btn:not(.active):hover {
  color: var(--primary);
}

.play-again {
  order: 2;
}

@media (max-width: 768px) {
  .play-again {
    font-size: 0.8rem;
    height: 34px;
  }
}

.reset-score {
  order: 3;
  @apply px-6 py-2;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: transparent;
  color: var(--foreground);
  border: 1px solid var(--primary);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .reset-score {
    font-size: 0.8rem;
    height: 34px;
  }
}

.reset-score:hover {
  background: var(--primary);
  color: var(--background);
}

@media (max-width: 768px) {

  .mode-toggle,
  .reset-score,
  .play-again {
    height: 36px;
    font-size: 0.8rem;
  }
}

.game-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  /* Add this instead */
  z-index: 0;
  /* Establish stacking context */
}

@media (max-width: 768px) {
  .game-center {
    order: -1;
    /* Move board to top on mobile */
    margin-top: 0;
    /* Remove top margin since status is fixed */
  }
}

@keyframes mobileMarkAppear {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes mobileMarkColorTransition {
  0% {
    color: var(--primary);
  }

  100% {
    color: var(--foreground);
  }
}

@keyframes mobileWinningMark {
  0% {
    transform: scale(1.2);
    filter: brightness(1);
  }

  50% {
    transform: scale(1.4);
    filter: brightness(1.5);
  }

  100% {
    transform: scale(1.2);
    filter: brightness(1);
  }
}